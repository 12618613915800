// @import url("https://fonts.googleapis.com/css?family=Lato:300,300i,400,700");
@import "./assets/css/fontawesome-all.min.css";
// @import "./components/UI/Input/Select/Select.css";
// Library style
@import "./customizations.scss";

@import "./variables.scss";
@import "./mixins.scss";

#root {
  // background-color: $ultra-light-grey;
  background-image: url("https://static.noku.io/assets/noku/bg-gradient-platform-xxl.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow-x: hidden;
}

.bodywrapper {
  background-color: #fff;
  min-height: 100vh;
  // box-shadow: 0px 0 4px 0px #fff;
}

@media all and (max-width: ($minimum-widthscreen - 1px)) {
  body,
  .bodywrapper {
    overflow-x: auto;
  }
}

@media all and (min-width: $xxxlarge) {
  .bodywrapper {
    max-width: $xxxlarge;
    margin: 0 auto;
    overflow: hidden;
  }
}

.offline {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999998;
  background-color: rgba(255, 255, 255, 0.8);

  &::after {
    content: "You're offline right now. \A Check your connection.";
    white-space: pre; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-weight: bold;
    color: $primary-color;
    font-size: 18px;
  }
}
.LoaderLayer svg {
  width: 100%;
  margin: 0 auto;
  display: block;
}

@keyframes big-to-small {
  0% {
    transform: none;
  }
  100% {
    transform: scale(.33) translate(1.8px, -1.5px);
    stroke-width: 6px;
  }
}

@keyframes small-to-big {
  0% {
    transform: none;
  }
  100% {
    transform: scale(3) translate(0px, -3px);
    stroke-width: .8;
  }
}

@keyframes loading-noku3 {
  0% {
    transform: none;
  }
  100% {
    transform: translate(-17.5px, 17.5px);
  }
}

.LoaderLayer svg path, .LoaderLayer svg line {
  transform-origin: top right;
}

.LoaderLayer svg g:first-child path {
  animation: big-to-small .7s infinite ease-in-out alternate;
  transform-origin: bottom left;
}

.LoaderLayer svg g path {
  animation: small-to-big .7s infinite ease-in-out alternate;
  transform-origin: top right;
}

.LoaderLayer svg line {
  animation: loading-noku3 .7s infinite ease-in-out alternate;
}

.LoaderLayer path {
  fill: none;
  stroke: #000000;
  stroke-width: 2;
  stroke-miterlimit: 10;
}

.LoaderLayer line {
  fill: none;
  stroke: #000000;
  stroke-miterlimit: 10;
}
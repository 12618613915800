@import "../../mixins.scss";

.loadingPage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-image: url("https://static.noku.io/assets/noku/bg-gradient-platform-xxl.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;

    .contentHolder {
        width: 100%;
        text-align: center;

        .logo {
            width: 540px;
            max-width: calc(100% - 60px);

            @include largeDevice {
                width: 340px;
            }
        }

        .loading {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 40px;

            .loader {
                width: 50px;
                svg {
                    path,
                    line {
                        stroke: white;
                    }
                }
            }
        }
    }
}

$primary-color: #4446ed;
$dark-primary-color: #2224c3;
$super-dark-primary-color: #1c1d9c;

$secondary-color: $primary-color;
$dark-secondary-color: $dark-primary-color;
$super-dark-secondary-color: $super-dark-primary-color;

//BUTTONS STYLE
$button-primary-color: #ffffff;
$button-primary-bg-color: $dark-primary-color;
$button-primary-hover-bg-color: $super-dark-primary-color;

$button-secondary-color: $primary-color;
$button-secondary-bg-color: #ffffff;
// $button-secondary-hover-bg-color: #ff0000; //unused if white

$link-header-container-padding: 8px;
$linear-gradient-to-color: #9616E5;

$logo-width: 102px;
@import "./variables.scss";

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}
@mixin transform($args...) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  transform: $args;
}
@mixin box-shadow($args...) {
  -webkit-box-shadow: $args;
  -moz-box-shadow: $args;
  box-shadow: $args;
}
@mixin icon-after($icon, $family: "icomoon-base", $display: inline-block) {
  content: $icon;
  font-family: $family;
  display: $display;
}
@mixin clear-after() {
  content: "";
  display: block;
  clear: both;
}
@mixin custom-border($color, $thickness, $length, $color-percentage) {
  background-image: linear-gradient(to right, $color $color-percentage, transparent 100% - $color-percentage),
    linear-gradient(to right, $color $color-percentage, transparent 100% - $color-percentage),
    linear-gradient(to bottom, $color $color-percentage, transparent 100% - $color-percentage),
    linear-gradient(to bottom, $color $color-percentage, transparent 100% - $color-percentage);
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: $length $thickness, $length $thickness, $thickness $length, $thickness $length;
}
@mixin arrow-up($width: 5px, $color: black) {
  width: 0;
  height: 0;
  border-left: $width solid transparent;
  border-right: $width solid transparent;
  border-bottom: $width solid $color;
  display: inline-block;
}
@mixin arrow-down($width: 5px, $color: black) {
  width: 0;
  height: 0;
  border-left: $width solid transparent;
  border-right: $width solid transparent;
  border-top: $width solid $color;
  display: inline-block;
}
@mixin arrow-right($width: 5px, $color: black) {
  width: 0;
  height: 0;
  border-top: $width solid transparent;
  border-bottom: $width solid transparent;
  border-left: $width solid $color;
  display: inline-block;
}
@mixin arrow-left($width: 5px, $color: black) {
  width: 0;
  height: 0;
  border-top: $width solid transparent;
  border-bottom: $width solid transparent;
  border-right: $width solid $color;
  display: inline-block;
}
@mixin aspect-ratio($width-ratio: 1, $height-ratio: 1) {
  position: relative;
  width: 100%;
  padding-top: #{$height-ratio / $width-ratio * 100%};
  > * {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
@mixin absolute-fill() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
@mixin extraLargeDevice() {
  @media screen and (max-width: ($xxlarge - 1px)) {
    @content;
  }
}
@mixin largeDevice() {
  @media screen and (max-width: ($xlarge - 1px)) {
    @content;
  }
}
@mixin mediumDevice() {
  @media screen and (max-width: ($large - 1px)) {
    @content;
  }
}
@mixin smallDevice() {
  @media screen and (max-width: ($medium - 1px)) {
    @content;
  }
}
@mixin extraSmallDevice() {
  @media screen and (max-width: ($small - 1px)) {
    @content;
  }
}

@mixin bigPhoneDevice() {
  @media screen and (max-width: ($extra-small - 1px)) {
    @content;
  }
}

@mixin smallestDevice() {
  @media screen and (max-width: ($minimum-widthscreen - 1px)) {
    @content;
  }
}

@mixin dividerTextSpanStyling() {
  display: inline-block;
  max-width: 100%;
  padding: 0 15px;
  margin-left: 30px;
  font-weight: 500;
  font-size: 12px;
  background-color: $white;
  box-sizing: border-box;

  @include smallDevice {
    display: none;
  }
}

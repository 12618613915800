//SASS AUTOCOMPILE

$button-border-radius: 5px;
$input-border-radius: 4px;
@import "./config/custom_variables.scss";

// General
$transition-time: 0.3s;

// Colors
$red: #c4282c;
$dark-red: #d0021a;

$white: white;

$grey: #4a4a4a;
$light-ish-grey: #5e5e5e;
$light-grey: #9b9b9b;
$extreme-light-grey: #e4e4e4;
$ultra-light-grey: #f5f5f5;
$super-light-grey: #f9f9fb;
$white: #ffffff;

$links-color: #4446ed;

$bg-disabled: $ultra-light-grey;

//Header
$headerHeight: 74px;
$headerBorderSize: 2px;

// Typography
$body-ff: 'Lato', sans-serif;
$title-ff: $body-ff;
$body-fs: 18px;
$body-fs-xs: 16px;
$fontAwesome: "Font Awesome 5 Pro";

// Buttons
$btn-primary-bg: $button-primary-hover-bg-color;

// Testate
$heading-height: 500px;
$heading-height-sm: 400px;
$heading-height-xs: 300px;

// Form
$form-control-height: 40px;
$form-control-fs: 16px;
$form-error-color: $red;

// Jcf
$checkbox-size: 14px;

$red-error: #c4282c;

// Screen breakpoints
$minimum-widthscreen: 320px; // actual minimum width
$small: 640px; 
$medium: 768px;
$mobile: $medium - 1px;
$large: 1024px;
$xlarge: 1200px;
$xxlarge: 1440px;
$xxxlarge: 1920px;
$xxxxlarge: 2160px;

//header
$headerHeight: 74px;
$headerBorderSize: 2px;

$headerHeightMobile: 64px;
$headerBorderSizeMobile: 0px;
